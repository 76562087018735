body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #F8F8F8;
}


html, body, #root {
  width: 100%;
  height: 100%;
}

/*#root {
  background: linear-gradient(45deg, #09B4B3, #6FB6B3, #09B4B3, #6FB6B3, #09B4B3);
}*/

.space-between {
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
}

.login-header {
  font-family: Brera, Arial;
  font-weight: 700;
  font-size: 28px;
  color: #901845;
  margin: 0;
}

.login-subheader {
  color: #054064;
  margin: 0;
}

.login-header-note {
  font-family: Brera, Arial;
  font-size: 16px;
  color: #707070;
}

.login-button {
  background-color: #05386F !important;
}

.login-container {
  background-color: #EBEBEB !important;
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16) !important;
  -moz-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16) !important;
  box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16) !important;
}

.fluid-form-container {
  background-color: #E4EFF2;
}

.privacy-note {
  text-align: center;
  color: #05386F;
  font-family: Brera, Arial;
  font-size: 13px;
  margin: 10px 0 50px 0;
}

#root {
  background-color: #D5E6EE;
}