@font-face {
    font-family: Brera;
    src: url(./assets/BreraRg.otf);
    font-weight: normal;
}

@font-face {
    font-family: Brera;
    src: url(./assets/BreraSb.otf);
    font-weight: 600;
}

@font-face {
    font-family: Brera;
    src: url(./assets/BreraBd.otf);
    font-weight: bold;
}

@font-face {
    font-family: Brera;
    src: url(./assets/BreraBl.otf);
    font-weight: 900;
}

@font-face {
    font-family: Brera;
    src: url(./assets/BreraLt.otf);
    font-weight: lighter;
}

@font-face {
    font-family: Brera;
    src: url(./assets//BreraTh.otf);
    font-weight: 100;
}